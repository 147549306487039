<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 17.8 17.019">
    <g id="Icon_feather-edit-3" data-name="Icon feather-edit-3" transform="translate(0.9 0.9)">
      <path
        id="Path_3089"
        data-name="Path 3089"
        d="M12,20h9"
        transform="translate(-5 -4.781)"
        fill="none"
        stroke="#b8bbc2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.8"
        :style="iconStyle"
      />
      <path
        id="Path_3090"
        data-name="Path 3090"
        d="M14.923,3.431a1.865,1.865,0,0,1,2.65,0,1.894,1.894,0,0,1,0,2.667L6.533,17.209,3,18.1l.883-3.556Z"
        transform="translate(-3 -2.879)"
        fill="none"
        stroke="#b8bbc2"
        stroke-linecap="round"
        stroke-linejoin="round"
        :style="iconStyle"
        stroke-width="1.8"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '17.019',
    },
    width: {
      type: String,
      default: '17.8',
    },
    color: {
      type: String,
      default: 'text-color',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
